<template>
  <b-sidebar
    id="create-folder-sidebar"
    :visible="iscreateFolderSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @hidden="resetForm"
    @change="(val) => $emit('update:is-create-folder-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div
        class="
          d-flex
          justify-content-between
          align-items-center
          content-sidebar-header
          px-2
          py-1
        "
      >
        <h5 class="mb-0">Create Folder</h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />
      </div>

      <!-- BODY -->
      <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm"
        >
          <!-- Full Name -->

          <validation-provider
            #default="validationContext"
            name="Folder Name"
            rules="required"
          >
            <b-form-group label="Folder Name" label-for="Folder Name">
              <b-form-input
                id="Folder-Name"
                v-model="folderName.name"
                autofocus
                trim
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-form>
      </validation-observer>
    </template>
    <template #footer="{ hide }">
      <div class="d-flex bg-light text-light align-items-center px-1 py-2">
        <!-- <div > -->
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="primary"
          type="submit"
          @click="onSubmit"
          :disabled="submitbutton"
        >
          Submit
        </b-button>
        <b-button
          v-ripple.400="'rgba(186, 191, 199, 0.15)'"
          class="ml-2"
          type="button"
          variant="outline-secondary"
          @click="hide"
        >
          Cancel
        </b-button>
      </div>
      <!-- </div> -->
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar,
  BForm,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BButton,
  BCol,
  BCardText,
  BMedia,
  BAlert,
  BMediaAside,
  BMediaBody,
  BLink,
  BInputGroup,
  BImg,
  BFormFile,
  BFormCheckbox,
  BFormSelect,
  BInputGroupAppend,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { ref } from "@vue/composition-api";
import { required, alphaNum, email } from "@validations";
import {
  togglePasswordVisibility,
  toggleConfirmPasswordVisibility,
  toggleOldPasswordVisibility,
} from "@core/mixins/ui/forms";
import formValidation from "@core/comp-functions/forms/form-validation";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import countries from "../../../../@fake-db/data/other/countries";
import store from "@/store";
import axios from "axios";
import colorPicker from "@caohenghu/vue-colorpicker";

import S3 from "aws-s3";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
export default {
  components: {
    BSidebar,
    BAlert,
    BCol,
    BForm,
    BFormGroup,
    BInputGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    BCardText,
    BMedia,
    BMediaAside,
    BMediaBody,
    colorPicker,
    BImg,
    BLink,
    BFormSelect,
    vSelect,
    BFormFile,
    BInputGroupAppend,
    ToastificationContent,
    // Form Validation
    BFormCheckbox,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: "iscreateFolderSidebarActive",
    event: "update:is-create-folder-sidebar-active",
  },
  props: {
    iscreateFolderSidebarActive: {
      type: Boolean,
      required: true,
    },
    folderType: {
      type: String,
      required: true,
    },
    // breadCrumb: {
    //   type: Array,
    //   required: true,
    // },
  },

  data() {
    return {
      folderName: {
        name: "",
      },
      alertMessageToast: "",
    };
  },

  computed: {
    submitbutton() {
      // console.log('this.userdata',this.userdata);
      return !(this.folderName.name.length > 0);
    },
  },

  methods: {
    onSubmit() {
      // this.folderName.breadCrumb = this.breadCrumb;
      this.folderName.parentId = this.folderType;
      axios
        .post(
          process.env.VUE_APP_API_URL + "/gallery/create-folder",
          this.folderName,
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("_t"),
            },
          }
        )
        .then((response) => {
          if (response.data.message == "Folder created successfully") {
            this.alertMessageToast = response.data.message;
            this.showToast("success");
            this.$emit("createfolderSuccess", true);
            this.$root.$emit("bv::toggle::collapse", "create-folder-sidebar");
          } else {
            this.alertMessageToast = "Folder not created try again";
            this.showToast("danger");
            this.$emit("createfolderSuccess", false);
            this.$root.$emit("bv::toggle::collapse", "create-folder-sidebar");
          }
        });
    },
    showToast(variant) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Notification",
          icon: "BellIcon",
          text: this.alertMessageToast,
          variant,
        },
      });
    },
    resetForm() {
      this.folderName = {
        name: "",
      };
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";

#create-folder-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>

